<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.05608 5L13.9361 17.1343L5 27H7.01116L14.8347 18.3624L21.1559 27H28L18.6203 14.1832L26.938 5H24.9268L17.7217 12.955L11.9001 5H5.05608ZM8.01364 6.51397H11.1578L25.042 25.4858H21.8978L8.01364 6.51397Z"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconX",
});
</script>
